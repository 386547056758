<template>
	<div>
		<CustomTable
			:recent-filter="recentFilters"
			:total-pages="totalPages"
			:total-items="totalItems"
			:label-search="$t('CustomerFilters.search')"
			:label-date="$t('CustomerFilters.created')"
			:show-create-button="true"
			:text-create-button="$t('CustomerButtons.create')"
			@search="search"
			@create="create"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headerWithRole"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
					>
						{{ header.title }}
					</th>
				</tr>
			</template>
			<template #body>
				<template v-if="customers && customers.length">
					<tr
						v-for="(item, index2) in customers"
						:key="`${item.id}-${index2}`"
						:class="{ 'highlight-row': item.id === idHighlightRow }"
					>
						<td scope="row" class="text-break text-center fit">
							{{ item.id }}
						</td>
						<td scope="row" class="text-break text-center see-more">
							{{ item.name }}
						</td>
						<td scope="row" class="text-break text-center">
							{{ item.emails }}
						</td>
						<td scope="row" class="text-break text-center fit">
							{{ convertDateToDateVN(item.createdDate) }}
						</td>
						<td scope="row" class="text-break text-center see-more">
							{{ item.rule }}
						</td>
						<td scope="row" class="fit text-center actions">
							<router-link
								:to="{
									name: 'UpdateCustomer',
									params: { id: item.id },
								}"
								class="link-icon"
							>
								<span class="prefix-input d-inline-block mr-3">
									<font-awesome-icon :icon="['fas', 'pencil-alt']" :title="$t('Action.Edit')" />
								</span>
							</router-link>
							<span class="prefix-input d-inline-block trash" @click="deleteCustomer(item.id)">
								<font-awesome-icon :icon="['fas', 'trash-alt']" :title="$t('Action.Delete')" />
							</span>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td colspan="11" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTable>
		<CustomModal
			v-if="showConfirmDelete"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Cancel')"
			@submit-modal="confirm"
			@cancel-modal="cancel"
		>
			<template #content>
				{{ $t("CustomerMessage.Delete") }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { USER_ROLE } from "@/shared/plugins/constants"
import { GET_CUSTOMERS, DELETE_CUSTOMER } from "../store/action-types"
import { RESET_CUSTOMER_FILTERS } from "../store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("customers")
export default {
	name: "CustomerManageement",
	data() {
		return {
			showConfirmDelete: false,
			customerId: null,
			headers: [
				{
					title: this.$t("CustomerFieldTable.ID"),
					class: "fit text-center",
				},
				{
					title: this.$t("CustomerFieldTable.Customer"),
					class: "text-nowrap title text-center",
				},
				{
					title: this.$t("CustomerFieldTable.Email"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("CustomerFieldTable.Created"),
					class: "text-nowrap text-center fit",
				},
				{
					title: this.$t("CustomerFieldTable.Rule"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("CustomerFieldTable.Actions"),
					class: "fit text-center",
				},
			],
		}
	},
	computed: {
		...mapState(["totalItems", "totalPages", "recentFilters", "customers", "idHighlightRow"]),
		currentUser() {
			return this.$store.state.authentication.currentUser
		},
		isSuperAdmin() {
			if (this.currentUser.role !== USER_ROLE.QA) {
				return true
			}
			return false
		},
		headerWithRole() {
			if (this.currentUser && !this.isSuperAdmin) {
				const newHeader = [...this.headers]
				const indexRemove = newHeader.findIndex(
					el => el.title === this.$t("CustomerFieldTable.Email"),
				)
				newHeader.splice(indexRemove, 1)
				return newHeader
			}
			return this.headers
		},
	},
	beforeDestroy() {
		if (!this.$route.path.includes("customers")) this.RESET_CUSTOMER_FILTERS()
	},
	methods: {
		...mapActions({ GET_CUSTOMERS, DELETE_CUSTOMER }),
		...mapMutations({ RESET_CUSTOMER_FILTERS }),
		async search(paramsSearch) {
			const params = {
				data: paramsSearch,
				notLoading: false,
			}
			await this.GET_CUSTOMERS(params)
		},
		create() {
			this.$router.push({ name: "CreateCustomer" })
		},
		async confirm() {
			const params = {
				id: this.customerId,
			}
			await this.DELETE_CUSTOMER(params)
			this.showConfirmDelete = false
			this.$store.commit("set", ["modalShow", false])
			this.customerId = null
			this.search(this.recentFilters)
		},
		cancel() {
			this.showConfirmDelete = false
		},
		deleteCustomer(id) {
			this.customerId = id
			this.showConfirmDelete = true
			this.$store.commit("set", ["modalShow", true])
		},
	},
}
</script>

<style></style>
